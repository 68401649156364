import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../assets/styles/GlobalVariables"

const StyledWrapper = styled.div`
  border: 1px solid ${({ isSelected }) => isSelected ? GlobalColors.primary : GlobalColors.gray600};
  width: 190px;
  height: 226px;
  border-radius: 4px;
  color: ${({ isSelected }) => isSelected ? GlobalColors.white : GlobalColors.gray600};
  text-align: center;
  padding-top: 170px;
  box-sizing: border-box;
  font-size: 3.2rem;
  font-family: Teko;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-position: top 20px center;
  background-size: 80%;
  cursor: pointer;
`

const PositionsListElement = ({ positionsCount, isSelected, image, className, changeNumberOfPositions }) => (
  <StyledWrapper isSelected={isSelected} image={image} className={className} onClick={changeNumberOfPositions}>
    <span>{positionsCount}</span>
  </StyledWrapper>
)

export default PositionsListElement
