class Calculator {
  calculatorData = {
    falowniki: true,
    cenaProszuku: 10,
    cenaWosku: 20,
    cenaAktywnejPiany: 8,
    cenaSoli: 20,
    kosztWodyZeSciekami: 8,
    kosztGazu: 2.6,
    kosztEnergiiElektrycznej: 0.64,
    czasTrwaniaProgramuPiana: 45,
    czasTrwaniaPozostalychProgramow: 60,
    iloscDozowanegoProszkuNaMin: 14,
    iloscDozowanegoWosku: 20,
    iloscDozowanejPiany: 0.012,
    iloscZuzytejWody: 14,
    PIEC: 1.03,
    zuzycieEnergiiPodczasPracy: 3.4,
    kalorycznoscGazu: 9435,
    temperaturaWodynaWejsciu: 10,
    temperaturaWodyNaWyjsciuWosk: 40,
    temperaturaWodyNaWyjsciuProszek: 60,
    przecietnyKosztMyciaPojazdu: 10
  }
  result = 0

  constructor() {
    this.calc();
  }


  calc() {
    const step1 = Number.parseFloat(this.calcStep1().toFixed(2));
    const step2 = Number.parseFloat(this.calcStep2().toFixed(2));
    const step3 = Number.parseFloat(this.calcStep3().toFixed(2));
    const step4 = Number.parseFloat(this.calcStep4().toFixed(2));
    const step5 = Number.parseFloat(this.calcStep5().toFixed(2));

    const allCost = Number.parseFloat((step1 + step2 + step3 + step4 + step5 + 0.07).toFixed(2));

    this.result = allCost;
    return allCost;
  }

  calcStep1() {
    const data = this.calculatorData;
    const piana = data.przecietnyKosztMyciaPojazdu * 0.2;
    const zuzycieWody = piana * 9 * data.czasTrwaniaProgramuPiana / 60;
    const zuzycieWodyKoszt = zuzycieWody * data.kosztWodyZeSciekami / 1000;
    const zuzycieEnergii = data.zuzycieEnergiiPodczasPracy * piana / 60 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieEnergiiKoszt = zuzycieEnergii * data.kosztEnergiiElektrycznej;
    const zuzycieGazu = piana * zuzycieWody * (32 - data.temperaturaWodynaWejsciu) / data.PIEC / data.kalorycznoscGazu * data.czasTrwaniaProgramuPiana / 60;
    const zuzycieGazuKoszt = zuzycieGazu * data.kosztGazu;
    const solKoszt = 0.01;
    const chemiaPlynna = 7000 * data.iloscDozowanejPiany;
    const chemiaPlynnaKoszt = chemiaPlynna * (data.cenaAktywnejPiany / 1000);
    return zuzycieWodyKoszt + zuzycieEnergiiKoszt + zuzycieGazuKoszt + solKoszt + chemiaPlynnaKoszt;
  }

  calcStep2() {
    const data = this.calculatorData;
    const mycie = data.przecietnyKosztMyciaPojazdu * 0.2;

    const zuzycieWody = mycie * data.iloscZuzytejWody * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieWodyKoszt = zuzycieWody * data.kosztWodyZeSciekami / 1000;

    const zuzycieEnergii = data.zuzycieEnergiiPodczasPracy * mycie / 60 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieEnergiiKoszt = zuzycieEnergii * data.kosztEnergiiElektrycznej;

    const zuzycieGazu = mycie * data.iloscZuzytejWody * (data.temperaturaWodyNaWyjsciuProszek - data.temperaturaWodynaWejsciu) / data.kalorycznoscGazu / data.PIEC * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieGazuKoszt = zuzycieGazu * data.kosztGazu;

    const solKoszt = 0.01;

    const proszek = mycie * data.iloscDozowanegoProszkuNaMin * data.czasTrwaniaPozostalychProgramow / 60;
    const proszekKoszt = proszek * data.cenaProszuku / 1000;

    return zuzycieWodyKoszt + zuzycieEnergiiKoszt + zuzycieGazuKoszt + solKoszt + proszekKoszt;
  }

  calcStep3() {
    const data = this.calculatorData;
    const splukiwanie = data.przecietnyKosztMyciaPojazdu * 0.2;

    const zuzycieWody = splukiwanie * data.iloscZuzytejWody * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieWodyKoszt = zuzycieWody * data.kosztWodyZeSciekami / 1000;

    const zuzycieEnergii = splukiwanie * data.zuzycieEnergiiPodczasPracy / 60 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieEnergiiKoszt = zuzycieEnergii * data.kosztEnergiiElektrycznej;

    return zuzycieWodyKoszt + zuzycieEnergiiKoszt;
  }

  calcStep4() {
    const data = this.calculatorData;
    const woskowanie = data.przecietnyKosztMyciaPojazdu * 0.2;

    const zuzycieWody = data.falowniki ? (woskowanie * data.iloscZuzytejWody * data.czasTrwaniaPozostalychProgramow / 60) * 0.65 : woskowanie * data.iloscZuzytejWody * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieWodyKoszt = zuzycieWody * data.kosztWodyZeSciekami / 1000;

    const zuzycieEnergii = data.falowniki ? (woskowanie * data.zuzycieEnergiiPodczasPracy / 60 * data.czasTrwaniaPozostalychProgramow / 60) * 0.65 : woskowanie * data.zuzycieEnergiiPodczasPracy / 60 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieEnergiiKoszt = zuzycieEnergii * data.kosztEnergiiElektrycznej;

    const zuzycieGazu = data.falowniki ? (woskowanie * data.iloscZuzytejWody * (data.temperaturaWodyNaWyjsciuWosk - data.temperaturaWodynaWejsciu) / data.PIEC / data.kalorycznoscGazu * data.czasTrwaniaPozostalychProgramow / 60) * 0.65 : woskowanie * data.iloscZuzytejWody * (data.temperaturaWodyNaWyjsciuWosk = data.temperaturaWodynaWejsciu) / data.PIEC / data.kalorycznoscGazu * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieGazuKoszt = zuzycieGazu * data.kosztGazu;

    const solKoszt = 0.01;

    const wosk = data.falowniki ? (woskowanie * data.iloscDozowanegoWosku * data.czasTrwaniaPozostalychProgramow / 60) * 0.65 : woskowanie * data.iloscDozowanegoWosku * data.czasTrwaniaPozostalychProgramow / 60;
    const woskKoszt = wosk * data.cenaWosku / 1000 / 4;

    return zuzycieWodyKoszt + zuzycieEnergiiKoszt + zuzycieGazuKoszt + solKoszt + woskKoszt;
  }

  calcStep5() {
    const data = this.calculatorData;
    const nablyszczanie = data.przecietnyKosztMyciaPojazdu * 0.2;

    const zuzycieWody = data.falowniki ? (nablyszczanie * data.iloscZuzytejWody * 2 * data.czasTrwaniaPozostalychProgramow / 60) * 0.8 : nablyszczanie * data.iloscZuzytejWody * 2 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieWodyKoszt = zuzycieWody * data.kosztWodyZeSciekami / 1000;

    const zuzycieEnergii = data.falowniki ? (nablyszczanie * data.zuzycieEnergiiPodczasPracy / 60 * data.czasTrwaniaPozostalychProgramow / 60) * 0.8 : nablyszczanie * data.zuzycieEnergiiPodczasPracy / 60 * data.czasTrwaniaPozostalychProgramow / 60;
    const zuzycieEnergiiKoszt = zuzycieEnergii * data.kosztEnergiiElektrycznej;

    const solKoszt = 0.01;

    return zuzycieWodyKoszt + zuzycieEnergiiKoszt + solKoszt;
  }
}

export default Calculator
