import React, { Component } from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import PositionsList from "../../Molecules/PositionsList/PositionsList"
import Input from "../../Atoms/Input"
import CalculatorResult from "../../Molecules/CalculatorResult/CalculatorResult"
import Calculator from "../../../Helpers/Calculator"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.section`
  background-color: ${GlobalColors.black};
`

const StyledHeader = styled.h2`
  color: ${GlobalColors.white};
  margin: 0;
`

const StyledUnderHeader = styled.h4`
  max-width: 635px;
  color: ${GlobalColors.gray600};
`

const StyledPositionsList = styled(PositionsList)`
  display: block;
  margin-top: 80px;
`

const StyledInputWrapper = styled.div`
  position: relative;
  
  span {
    color: ${GlobalColors.gray500};
    position: absolute;
    left: 100px;
    top: 45px;
  }
`

const StyledInput = styled(Input)`
  margin-top: 40px;
  color: ${GlobalColors.white};
  
  input {
    width: 85px;
    display: inline-block;
  }
`

const StyledCalculatorResult = styled(CalculatorResult)`
  margin-top: 80px;
`

class SmallCalculator extends Component {
  calculator = new Calculator()
  onChangeTimeout = null
  state = {
    selectedWash: 0,
    cars: 20,
    duration: 55,
    result: {
      expense: 0,
      income: 0,
      profit: 0,
    },
  }

  componentDidMount() {
    this.calcProfit()
  }

  changeNumberOfPositions = (index) => {
    this.setState(() => ({ selectedWash: index }), () => this.calcProfit())
  }

  handleChangeTime = (e) => {
    this.calculator.calculatorData.czasTrwaniaProgramuPiana = e.target.value
    this.calculator.calculatorData.czasTrwaniaPozostalychProgramow = e.target.value
    this.setState({ duration: e.target.value })

    this.onChangeTimeout = setTimeout(() => this.calcProfit(), 500)
  }

  handleChangeCars = (e) => {
    this.setState({ cars: e.target.value })
    this.onChangeTimeout = setTimeout(() => this.calcProfit(), 500)
  }


  calcProfit = () => {
    const costOfOneCar = this.calculator.calculatorData.przecietnyKosztMyciaPojazdu
    const cars = this.state.cars
    const numberOfPlaces = this.state.selectedWash + 2
    const result = this.calculator.result

    this.calculator.calc()

    this.setState({
      result: {
        expense: Number.parseFloat(((result * cars * numberOfPlaces) * 30).toFixed(2)),
        income: (costOfOneCar * cars * numberOfPlaces) * 30,
        profit: Number.parseFloat((((costOfOneCar - result) * cars * numberOfPlaces) * 30).toFixed(2)),
      },
    })

  }

  render() {
    return (
      <StyledWrapper className="pt16 pb16">
        <div className="container">
          <StyledHeader className="header2">{localize("161")}</StyledHeader>
          <StyledUnderHeader className="header4">{localize("162")}</StyledUnderHeader>
          <StyledPositionsList selectedWash={this.state.selectedWash}
                               changeNumberOfPositions={(index) => this.changeNumberOfPositions(index)}/>
          <StyledInputWrapper>
            <StyledInput type="number" isRevert label={`2. ${localize("164")}`}
                         placeholder={localize("165")}
                         onChange={(e) => this.handleChangeTime(e)}
                         value={this.state.duration}
                         min={1}
            />
            <span className="body">{localize("166")}</span>
          </StyledInputWrapper>
          <StyledInput type="number" isRevert label={`3. ${localize("167")}`}
                       placeholder={localize("168")}
                       onChange={(e) => this.handleChangeCars(e)}
                       value={this.state.cars}
                       min={1}
          />
          <StyledCalculatorResult result={this.state.result}/>
        </div>
      </StyledWrapper>
    )
  }
}

export default SmallCalculator
