import carWashIcon1 from '../assets/images/calculator1.png'
import carWashIcon2 from '../assets/images/calculator2.png'
import carWashIcon3 from '../assets/images/calculator3.png'
import carWashIcon4 from '../assets/images/calculator4.png'
import carWashIcon5 from '../assets/images/calculator5.png'

export const PositionsListData = [
  {
    positions: 2,
    image: carWashIcon1
  },
  {
    positions: 3,
    image: carWashIcon2
  },
  {
    positions: 4,
    image: carWashIcon3
  },
  {
    positions: 5,
    image: carWashIcon4
  },
  {
    positions: 6,
    image: carWashIcon5
  },
]
