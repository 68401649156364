import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import CountUp from "react-countup"
import { localize } from "../../Atoms/Language"

const StyledWrapper = styled.div`
  background-color: ${GlobalColors.primary};
  height: 214px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 60px;
  color: ${GlobalColors.white};
  font-family: Teko;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  
  div {
    flex: 1;
  }
  
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 20px;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    
    div {
      margin-bottom: 10px;
      :last-child {
        margin-bottom: 0;
      }
    }  
  }
`

const StyledLabel = styled.div`
  font-size: 2.4rem;
  line-height: 2.4rem;
`

const StyledResult = styled(CountUp)`
  font-size: 6.4rem;
  line-height: 6rem;
`

const CalculatorResult = ({className, result}) => (
  <StyledWrapper className={className}>
    <div>
      <StyledLabel>{localize("169")}</StyledLabel>
      <StyledResult duration={.75} separator=" " suffix=" zł"  end={result.expense}/>
    </div>
    <div>
      <StyledLabel>{localize("170")}</StyledLabel>
      <StyledResult duration={.75} separator=" " suffix=" zł" end={result.income}/>
    </div>
    <div>
      <StyledLabel>{localize("171")}</StyledLabel>
      <StyledResult duration={.75} separator=" " suffix=" zł" end={result.profit} />
    </div>
  </StyledWrapper>
)

export default CalculatorResult
