import React from "react"
import styled from "styled-components"
import PositionsListElement from "../../Atoms/PositionsListElement"
import { PositionsListData } from "../../../Helpers/PositionsListData"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import { localize } from "../../Atoms/Language"

const StyledList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledLabel = styled.span`
  display: block;
  color: ${GlobalColors.white};
  font-family: Teko;
  text-transform: uppercase;
`

const StyledPositionsListElement = styled(PositionsListElement)`
  @media screen and (max-width: 1024px) {
    width: calc(50% - 10px);
    background-size: 150px;
    margin-bottom: 20px;
    
    :last-child {
      width: 100%;
      margin-bottom: 0;
      background-size: 200px;
    }
  }
  
  @media screen and (max-width: 450px) {
    width: 100%;
    background-size: 150px;
    margin-bottom: 20px;
  }
`

const PositionsList = ({className, selectedWash, changeNumberOfPositions}) => (
  <div className={className}>
    <StyledLabel className="header5">1. {localize("163")}</StyledLabel>
    <StyledList>
      {PositionsListData.map((carWash, index) =>
        <StyledPositionsListElement key={carWash.positions} positionsCount={carWash.positions} changeNumberOfPositions={() => changeNumberOfPositions(index)} isSelected={index === selectedWash} image={carWash.image}/>,
      )}
    </StyledList>
  </div>
)

export default PositionsList
